import { createError, defineNuxtRouteMiddleware, navigateTo } from '#imports'

import type r4 from 'fhir/r4'

import { getSourcesStore } from '@/stores/sources'
import { getItemPathByLinkId } from '@/utils/resources'

export default defineNuxtRouteMiddleware(async (to) => {
  const sources = await getSourcesStore()

  const source = sources.getById(String(to.params.id))

  const questionnaireItems =
    (source?.jsonObject as r4.Questionnaire | undefined)?.item || []
  let foundPath: string | undefined = undefined

  for (
    let itemIndex = 0;
    itemIndex < questionnaireItems.length;
    itemIndex += 1
  ) {
    foundPath = getItemPathByLinkId(
      source?.jsonObject as r4.Questionnaire,
      String(to.query.context),
      `Questionnaire.item[${itemIndex}]`,
    )

    if (foundPath) {
      return
    }
  }

  if (!foundPath && questionnaireItems.length > 0) {
    return navigateTo({
      ...to,
      query: { ...to.query, context: questionnaireItems[0].linkId },
    })
  }

  return createError({ statusCode: 404 })
})
